@import '../node_modules/include-media/dist/include-media';


body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


$monopol: 'FtMonopol';
$text: #2C2E2F;
$white: #ffffff;
$bg: #F5F7FA;
$main: #0671B9;
$dark: #003087;
$border: #9c9c9c;
$lightgrey: #c2c2c2;
$red: rgb(192, 0, 0);


@for $i from 1 through 10 {
  @font-face {
    font-family: $monopol;
    src: url('./fonts/FtMonopol\ 2.08-VF.ttf');
    font-weight: $i * 100;
  }
}


body {
  margin: 0;
  direction: rtl;
  color: $text;
  background-color: $bg;
  font-family: $monopol, "FtMonopol 2.08-", Arial, Helvetica, sans-serif;
  font-size: 0.85em;

  @include media('>=tablet') {
    font-size: 1em;
  }
}

input,
button,
textarea {
  font-family: inherit;
}

.wrap {
  max-width: 30em;
  margin: 0 auto;
}

header {
  background-color: $white;
  padding: 1em 0 1.5em;
  margin-bottom: 2em;
  text-align: center;

  img {
    margin: 0 0.9em;
    width: 7em;
    display: inline-block;
  }

  .paypal-logo {
    margin-bottom: 0.05em;
  }

  .pelecard-logo {}
}

.bordered {
  border-radius: 1.8em;
  background-color: $white;
  box-shadow: rgba(6, 113, 185, 0.10) 0 0.4em 1em -0.2em, rgba(6, 113, 185, 0.07) 0 0.2em 0.3em -0.2em;
  padding: 1.8em;
  overflow: hidden;
  position: relative;
  margin: 0 2em;

  &.more-padding {
    padding: 2.5em 3em;
  }
}

button,
.button {
  background-color: $main;
  color: $white;
  display: inline-block;
  font-weight: 400;
  border: 0;
  cursor: pointer;
  padding: 1em 1.5em 0.9em;
  font-size: 1em;
  text-decoration: none;
  border-radius: 12em;
  transition: color 0.2s ease, background-color 0.2s ease, border-color 0.2s ease, box-shadow 0.2s ease;

  &:hover {
    background: $dark;
  }

  &.fullwidth {
    display: block;
    width: 100%;
  }

  &.hollow {
    background: none;
    color: $main;
    box-shadow: inset 0 0 0 1px $main;

    &:hover {
      color: $dark;
      box-shadow: inset 0 0 0 2px $dark;
    }
  }
}

.mt-3 {
  margin-top: 0.7em;
}

.whats-happening {
  text-align: center;
  margin-bottom: 1.5em;
  font-size: 0.9em;

  .send-to {}

  .reciever {
    font-size: 1.2em;
    font-weight: 500;
    margin: 0.25em 0;
    font-variant-ligatures: none;
  }

  .for {}
}

#hide {
  position: absolute;
  // margin-top: 1em;
  opacity: 0;
  z-index: -100;
  color: red;
  white-space: pre;
  pointer-events: none;
}

.form-group {
  margin-bottom: 0.9em;

  label {
    display: block;
    font-weight: 700;
    font-size: 0.85em;
    margin: 0 0 0.3em;
    text-indent: -0.1em;
  }

  input[type=text],
  input[type=email] {
    display: block;
    width: 100%;
    box-sizing: border-box;
    border: none;
    border-radius: 0.3em;
    box-shadow: 0 0 0 1px $border;
    padding: 0.7em 0.8em;
    font-size: inherit;

    &:focus {
      outline: 0;
      box-shadow: 0 0 0 2px $main;
    }
  }


}


form {

  .want-kabala {
    cursor: pointer;

    &:hover {
      .checkbox {
        outline: 0;
        box-shadow: 0 0 0 2px $main;
      }
    }

    .checkbox {
      box-shadow: 0 0 0 1px $border;
      font-size: inherit;
      cursor: pointer;
      width: 1.5em;
      height: 1.5em;
      display: inline-block;
      margin: 0 0 -0.4em 0.7em;
      border-radius: 0.3em;

    }
  }

  .kabala {
    margin: 1em 0.6em 1.4em;
  }

  .amount-wrap {
    font-size: 3em;
    font-weight: 300;
    text-align: center;

    input {
      width: initial;
      font-size: inherit;
      border: none;
      color: inherit;
      // direction: ltr;
      font-weight: inherit;

      &.is-zero {
        min-width: 2.33em;
      }

      &::placeholder {
        color: inherit;
      }

      &:focus {
        outline: none;
      }

      /* Chrome, Safari, Edge, Opera */
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }

    .currency {
      font-size: 0.7em;
      font-weight: 400;
      // position: relative;
      // top: -0.4em;
    }
  }
}

.centered {
  text-align: center;
}
.middle {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

h1 {
  margin-top: 0;
  margin-bottom: 0.2em;
  font-weight: 500;
}

p {
  margin: 0 0 0.6em;
}

.nowrap {
  white-space: nowrap;
}

.loading-wrap {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: wait;

  .background {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: $white;

    @keyframes fadeIn {
      from {
        opacity: 0;
      }

      to {
        opacity: 0.98;
      }
    }

    animation: fadeIn 200ms ease-out forwards;
  }

  .loading {
    position: relative;
    text-align: center;

    @keyframes fadeInLoading {
      from {
        opacity: 0;
        transform: scale(0.9);
      }

      to {
        opacity: 1;
        transform: scale(1);
      }
    }

    opacity: 0;
    animation: fadeInLoading 200ms ease-out forwards 250ms;

    @keyframes rotating {
      from {
        transform: rotate(0deg);
      }

      to {
        transform: rotate(360deg);
      }
    }

    .spinner {
      width: 1em;
      height: 1em;
      border-radius: 1em;
      font-size: 4em;
      display: inline-block;
      border: 4px $lightgrey solid;
      border-right-color: $main;
      // border: 4px $main solid;
      // border-right-color: transparent;
      animation: rotating 1s linear infinite;
    }

    .text {
      margin-top: 1em;
      font-weight: 700;
    }
  }

  &.for-one-second {
    $delay: 2300ms;

    .background {
      opacity: 0.98;
      background: $bg;

      @keyframes fadeOut {
        from {
          opacity: 0.98;
        }

        to {
          opacity: 0;
        }
      }

      animation: fadeOut 200ms ease-out forwards ($delay+230ms);
    }

    .loading {
      @keyframes fadeOutLoading {
        from {
          opacity: 1;
          transform: scale(1);
        }

        to {
          opacity: 0;
          transform: scale(0.9);
        }
      }

      opacity: 1;
      animation: fadeOutLoading 200ms ease-out forwards $delay;
    }
  }
}

.error {
  color: $red;
  font-weight: 700;
  font-size: 0.85em;
  margin-top: 0.5em;
}

input[type=checkbox].hidden {
  opacity: 0;
  position: absolute;
  pointer-events: none;
}

.amount-message {
  text-align: center;
  margin-top: -0.3em;
  margin-bottom: 1.7em;
}